<template>
  <!-- 此页面为不同项目域名的演示流程审核成功页面（不调接口） -->
  <div class="passedSuccess-box">
    <div class="u-flex-col u-col-center passedSuccess-item">
      <img
        class="icon-success"
        src="../assets/images/icon_submit_success.png"
      />
      <div class="carLoanSuccess-tit">初审通过注意接听电话</div>
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
export default {
  name: "InterProPassedSuccess",
  data() {
    return {
      showPop: false, // 分享蒙层状态
    };
  },
  mounted() {
    // 自定义网站ico
    this.updateIcon();
  },
  methods: {
    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },

    // 自定义网站ico
    updateIcon() {
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.rel = "shortcut icon";
      link.type = "image/x-icon";
      link.href = require("../assets/images/interactivePro_logo.png");
      document.getElementsByTagName("head")[0].appendChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.passedSuccess-box {
  height: 100vh;
  padding: 0.36rem;
  overflow: auto;
  background: url("../assets/images/borrow_much_bg.png") no-repeat top left;
  background-size: 100%;

  .passedSuccess-item {
    padding: 2.74rem 0.34rem 0.82rem;
    font-size: 0.28rem;
    font-weight: 400;
    text-align: center;
    color: #2857f6;
  }

  .icon-success {
    width: 2.18rem;
    height: 1.76rem;
    margin-bottom: 0.32rem;
  }

  .carLoanSuccess-tit {
    margin-bottom: 0.06rem;
  }
}
</style>
